/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(function () {
          $('[data-toggle="tooltip"]').tooltip();
        });

        $(".slides").slick({
          arrows: false,
          infinite: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          cssEase: 'linear'
        });

        $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              event.preventDefault();
              $('html, body').animate({
                scrollTop: target.offset().top
              }, 1000, function() {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                  return false;
                } else {
                  $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                }
              });
            }
          }
        });

        $('.table-prices tbody tr td.column-1[colspan=4]').addClass('block-start');

        // fixed header resize
/*
        var header = $("header.banner");
        $(window).scroll(function() {
          var scroll = $(window).scrollTop();

          if (scroll >= 100) {
            header.addClass("scrolled");
          } else {
            header.removeClass("scrolled");
          }
        });
*/
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // QUOTE page
    'quote': {
      init: function() {
        // JavaScript to be fired on the quote page

        $('input#gform_target_page_number_1').val('1');

//         $("#gform_submit_button_1").detach().appendTo('.requestQuote');

/*
        $(".requestQuote .btn").click( function() {
          alert('a');
          $('#gform_1').submit();
        });
*/

        // Set Additional Tests & Stampers to 0
        $('.gform_wrapper .gform_body .gform_fields .gfield.price-quantity .ginput_quantity,#ginput_quantity_1_45').attr("value", "0");

        /* ========
        STARTS SIDEBAR
        ==========*/
        // Vinyl
        $('#input_1_18').change('input', function() {
          $('#vinyl-subtotal .price').html("$"+parseFloat($("#input_1_18").val()).toFixed(2));
        });
        // Test Press
        $('#input_1_19').change('input', function() {
          $('#testpress-subtotal .price').html("$"+parseFloat($("#input_1_19").val()).toFixed(2));
        });
        // Label + Sleeve Press
        $('#input_1_36').change('input', function() {
          $('#label-sleeve-subtotal .price').html("$"+parseFloat($("#input_1_36").val()).toFixed(2));
        });
        // Additional Stampers (includes reveal)
        $('#input_1_47').change('input', function() {
          if ($("#input_1_47").val()>0) {
            $('#stampers-subtotal').removeClass('sr-only');
          } else {
            $('#stampers-subtotal').addClass('sr-only');
          }
          $('#stampers-subtotal .price').html("$"+parseFloat($("#input_1_47").val()).toFixed(2));
        });
        // Mastering (includes reveal)
        $('#input_1_63').change('input', function() {
          if ($("#input_1_63").val()>0) {
            $('#mastering-subtotal').removeClass('sr-only');
          } else {
            $('#mastering-subtotal').addClass('sr-only');
          }
          $('#mastering-subtotal .price').html("$"+parseFloat($("#input_1_63").val()).toFixed(2));
        });
        // Plating (includes reveal)
        $('#input_1_64').change('input', function() {
          if ($("#input_1_64").val()>0) {
            $('#plating-subtotal').removeClass('sr-only');
          } else {
            $('#plating-subtotal').addClass('sr-only');
          }
          $('#plating-subtotal .price').html("$"+parseFloat($("#input_1_64").val()).toFixed(2));
        });
        // Wrapping (includes reveal)
        $('#input_1_50').change('input', function() {
          if ($("#input_1_50").val()>0) {
            $('#wrapping-subtotal').removeClass('sr-only');
          } else {
            $('#wrapping-subtotal').addClass('sr-only');
          }
          $('#wrapping-subtotal .price').html("$"+parseFloat($("#input_1_50").val()).toFixed(2));
        });
        // Inserts (includes reveal)
        $('#input_1_67').change('input', function() {
          if ($("#input_1_67").val()>0) {
            $('#inserts-subtotal').removeClass('sr-only');
          } else {
            $('#inserts-subtotal').addClass('sr-only');
          }
          $('#inserts-subtotal .price').html("$"+parseFloat($("#input_1_67").val()).toFixed(2));
        });
        // Stcikers (includes reveal)
        $('#input_1_53').change('input', function() {
          if ($("#input_1_53").val()>0) {
            $('#stickers-subtotal').removeClass('sr-only');
          } else {
            $('#stickers-subtotal').addClass('sr-only');
          }
          $('#stickers-subtotal .price').html("$"+parseFloat($("#input_1_53").val()).toFixed(2));
        });
        // Download Cards (includes reveal)
        $('#input_1_44').change('input', function() {
          var DLquantity = $("#input_1_43").val();
          var DLcost = $("#input_1_44").val();
          if (DLcost>0) {
            $('#download-cards-subtotal').removeClass('sr-only');
          } else {
            $('#download-cards-subtotal').addClass('sr-only');
          }
          if (DLquantity<300) { // fee for less than 300 download cards
            DLcost = parseFloat(DLcost) + parseFloat(50);
            $("#input_1_44").val(DLcost);
          }
          $('#download-cards-subtotal .price').html("$"+parseFloat($("#input_1_44").val()).toFixed(2));
        });



        //master, plating, setup
//         $('#mastering-subtotal .price').html($("#ginput_base_price_1_27").val());
//         $('#plating-subtotal .price').html($("#ginput_base_price_1_28").val());
        $('#setup-subtotal .price').html($("#ginput_base_price_1_29").val());

        // default vinyl mockup back to black
        $("#field_1_6 .ginput_container_select select").change(function() {
          $('#mockup').css('background-color','#000');
        });
        /* ========
        END SIDEBAR
        ==========*/


        // INSERTING QUANTITY - center label,sleeves,jackets,download cards,wrapping,stickers  -- value based on quantity value
        $('#input_1_35,#input_1_38,#input_1_41,#input_1_43,#input_1_49,#input_1_52,#input_1_66').val($("#input_1_7").val());
        $('#input_1_7').bind('input', function() {
          var vinylquantity = $("#input_1_7").val();
          var labeltype = $("#input_1_30").val();
          var sleevetype = $("#input_1_37").val();
          var jackettype = $("#input_1_40").val();
          var inserttype = $("#input_1_65").val();
          $('#input_1_35,#input_1_38,#input_1_41,#input_1_43,#input_1_49,#input_1_52,#input_1_66').val(vinylquantity);

          // printed label - 500 minimum
          if (vinylquantity<500 && labeltype!=="LABELS: Blank|0.2" && labeltype!=="LABELS: Sending Own|0.05") {
            $('#input_1_35').val(500);
          }
          // printed sleeve - 500 minimum
          if (vinylquantity<500 && (sleevetype=="SLEEVES: Custom Color|1.4" || sleevetype=="SLEEVES: Custom Black and White|0.8")) {
            $('#input_1_38').val(500);
          }
          // printed jacket - 300 minimum
          if (vinylquantity<300 && (jackettype=="JACKETS: B&W|1.7" || jackettype=="JACKETS: Full Color|2.05")) {
            $('#input_1_41').val(300);
          }
          if (vinylquantity<500 && jackettype=="JACKETS: Gatefold") {
            $('#input_1_41').val(500);
          }
          // printed INSERTS - 300 minimum
          if (vinylquantity<300 && inserttype!=="INSERTS: None|0" && inserttype!=="INSERTS: Sending Own|0.1") {
            $('#input_1_66').val(300);
          }
        });

        // printed label - 500 minimum
        $('#input_1_30').change('input', function() {
          var vinylquantity = $("#input_1_7").val();
          var labeltype = $("#input_1_30").val();
          if (vinylquantity<500) {
            $('#input_1_35').val(vinylquantity);
            if (labeltype!=="LABELS: Blank|0.2" && labeltype!=="LABELS: Sending Own|0.05") {
              $('#input_1_35').val(500);
            }
          }
        });
        // printed sleeve - 500 minimum
        $('#input_1_37').change('input', function() {
          var vinylquantity = $("#input_1_7").val();
          var sleevetype = $("#input_1_37").val();
          if (vinylquantity<500) {
            $('#input_1_38').val(vinylquantity);
            if (sleevetype=="SLEEVES: Custom Color|1.4" || sleevetype=="SLEEVES: Custom Black and White|0.8") {
              $('#input_1_38').val(500);
            }
          }
        });
        // printed jacket - 300 minimum
        $('#input_1_40').change('input', function() {
          var vinylquantity = $("#input_1_7").val();
          var jackettype = $("#input_1_40").val();
          if (vinylquantity<300) {
            $('#input_1_41').val(vinylquantity);
            if (jackettype=="JACKETS: B&W|1.7" || jackettype=="JACKETS: Full Color|2.05") {
              $('#input_1_41').val(300);
            }
          }
          if (vinylquantity<500 && jackettype=="JACKETS: Gatefold|3.5") {
            $('#input_1_41').val(500);
          }
        });
        // printed insert - 300 minimum
        $('#input_1_65').change('input', function() {
          var vinylquantity = $("#input_1_7").val();
          var inserttype = $("#input_1_65").val();
          if (vinylquantity<300) {
            $('#input_1_66').val(vinylquantity);
            if (inserttype!=="INSERTS: None|0" && inserttype!=="INSERTS: Sending Own|0.1") {
              $('#input_1_66').val(300);
            }
          }
        });

        // machine set up fees -- affected by color choice
        $('#ginput_base_price_1_29').change(function(){
          $('#setup-subtotal .price').html($("#ginput_base_price_1_29").val());
        });

        $('.color-change').click(function(){
          $('#input_1_26').val($(this).data('val')).trigger('change');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the quote page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
